import moment from 'moment';


export function statusConvert(staus){
	let statuss = {
		new:'Nuovo in fase di approvazione',
		PREP:'In preparazione',
		APR:'Accettato',
		SPED:'Spedito/Pronto per il ritiro',
		CHIUD:'Concluso',
    RIFIUTA:'rifiutato/annullato',
    ACCETTA:'Accettato',
    CHIUDI:'Concluso'

	}
	return statuss[staus];
}

export function TotalP(carts,item){
	let subTotal = 0;

  if(carts){
    if(carts && carts.type != 'take_away'){
      carts.items.forEach((x,i)=>{
        if(x.custom){
          if(x.qty && x.qty > 0){
              if(x.sconto > 0){
                subTotal = subTotal + ((x.qty * x.total)-(x.qty * x.total)*x.sconto/100);
              }else {
                subTotal = subTotal + (x.qty * x.total);
              }

          }
        }else {
          if(x.qty && x.qty > 0){
              if(x.sconto > 0){
                subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.sconto/100);
              }else {
                subTotal = subTotal + (x.qty * x.prezzo);
              }

          }
        }


      })
    }else {
      carts.items.forEach((x,i)=>{
        if(x.custom){
          if(x.qty && x.qty > 0){
              if(x.scontoAsp > 0){
                subTotal = subTotal + ((x.qty * x.total)-(x.qty * x.total)*x.scontoAsp/100);
              }else {
                subTotal = subTotal + (x.qty * x.total);
              }

          }
        }else {
          if(x.qty && x.qty > 0){
              if(x.scontoAsp > 0){
                subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.scontoAsp/100);
              }else {
                subTotal = subTotal + (x.qty * x.prezzo);
              }

          }
        }


      })
    }

	}
  if(carts.type != 'take_away'){
    subTotal = Number(subTotal)+Number(item ? item.prezzoConsegna : null)
  }

	return subTotal
}

export function decimal(num) {
  var n = num.toFixed(2);
  return n
}

export function subTotal(carts){

	let subTotal = 0;
	if(carts){
		carts.items.forEach((x,i)=>{
      if(x.custom){
        if(x.qty && x.qty > 0){
        	subTotal = subTotal + (x.qty * x.total);
        }
      }else {
        if(x.qty && x.qty > 0){
        	subTotal = subTotal + (x.qty * x.prezzo);
        }
      }

		})
	}

	return decimal(subTotal)
}

export function stampto(unix_timestamp,noTime){
  if(noTime){
    return moment(Number(unix_timestamp)).format("DD-MM-YYYY")
  }else{
    return moment(Number(unix_timestamp)).format("DD-MM-YYYY HH:mm")
  }
	



}

function isNow(start,end){
  console.log('mostro nowwww');
  console.log('vediamo start');
  let ccc = moment().format('HH:mm')
  let startN = start;
  let endN=end;
  console.log('formattt');
  console.log(ccc);
  console.log(startN);
  console.log(endN);
  let value = moment(moment(ccc, 'HH:mm')).isBetween(moment(startN, 'HH:mm'), moment(endN, 'HH:mm'));
  console.log('risultato');
  console.log(value);
  return value

}

export function getTimeStops(time,items,prenoTypr){
  var startTime = moment(time[0], 'HH:mm');
  var bisTime = moment(time[0], 'HH:mm');
  var endTime = moment(time[1], 'HH:mm');

  if( endTime.isBefore(startTime) ){
    endTime.add(1, 'day');
  }

  var timeStops = [];

  while(startTime <= endTime){
    let itemToUserFor =  bisTime.add(30, 'minutes')
    let toTiem = new moment(startTime).format('HH:mm') + '-'+new moment(itemToUserFor).format('HH:mm')
    
    
    let id = toTiem.replace(/:/g, '');
    id = id.replace(/-/g, '');
    let ccc = moment().format('HH:mm')

			let active = false
		if(items && items.block_daily_p){
			 active = items.block_daily_p[id];
    }
    if(prenoTypr){
      toTiem = new moment(startTime).format('HH:mm')+" tavolo riservato per un'ora"
    }


    let Before = moment(moment(ccc, 'HH:mm')).isBefore(moment(itemToUserFor, 'HH:mm'));
    if(Before){
      if(isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm'))){
        if(prenoTypr){
          
        }else{
          timeStops.push({
            //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
            value: toTiem,
            label: 'prima possibile',
            string:toTiem,
            isDisabled: active,
            id:id,
            //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
          });
        }
        
      }else {
        if(prenoTypr){
          if(!active){
            timeStops.push({
              //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
              value: toTiem,
              label:  toTiem + ' ' + (active ? 'esaurito' : ''),
              string:toTiem,
              isDisabled: active,
              id:id,
              //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
            });
          }
        }else{
          timeStops.push({
            //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
            value: toTiem,
            label:  toTiem + ' ' + (active ? 'esaurito' : ''),
            string:toTiem,
            isDisabled: active,
            id:id,
            //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
          });
        }
        
      }

    }

    startTime.add(30, 'minutes');
  }

  return timeStops;
}
