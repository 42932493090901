
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {TotalP} from '../../util/functions.js'

import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import { PayPalButton } from "react-paypal-button-v2";
import * as ROUTES from '../../constants/routes';
import Footer from '../Footer';
import {Helmet} from "react-helmet";
import moment from 'moment';
import Select from 'react-select';
import Logo from '../img/logoRis.jpeg';
import Cart from '../Cart';
import Header from '../Header';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from '../StripeP'

const axios = require('axios')
const stripePromise = loadStripe('pk_test_rFDgx5pPYhBrtBn6FsiZb6w1');

var idP = process.env.REACT_APP_RESTORANT_TOKEN;
var idT = 'ATvw-INv6YwISTxEF3mveUQuXI3ZHOZYwqtLQcK9lVSb7lXOTrPcfAiJ_Obgbr4yeEKJdG7yvT83YHMZ'

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

function decimal(num) {
  var n = num.toFixed(2);
  return n
}

function isNow(start,end){
  console.log('mostro nowwww');
  console.log('vediamo start');
  let ccc = moment().format('HH:mm')
  let startN = start;
  let endN=end;
  console.log('formattt');
  console.log(ccc);
  console.log(startN);
  console.log(endN);
  let value = moment(moment(ccc, 'HH:mm')).isBetween(moment(startN, 'HH:mm'), moment(endN, 'HH:mm'));
  console.log('risultato');
  console.log(value);
  return value

}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit==="K") { dist = dist * 1.609344 }
		if (unit==="N") { dist = dist * 0.8684 }
		return dist;
	}
}

function getTimeStops(time,items,type){
  var startTime = moment(time[0], 'HH:mm');
  var bisTime = moment(time[0], 'HH:mm');
  var endTime = moment(time[1], 'HH:mm');

  if( endTime.isBefore(startTime) ){
    endTime.add(1, 'day');
  }

  var timeStops = [];

  while(startTime <= endTime){
    let itemToUserFor =  bisTime.add(30, 'minutes')
    let toTiem = ''+new moment(itemToUserFor).format('HH:mm')
    let id = toTiem.replace(/:/g, '');
    id = id.replace(/-/g, '');
    let ccc = moment().format('HH:mm')

    console.log('mostimao active');
    console.log(items.block_takeA);
    let active = items.block_takeA ? items.block_takeA[id] : false;
    console.log(id);

    //console.log(items.block_daily_p[id]);

    let Before = moment(moment(ccc, 'HH:mm')).isBefore(moment(itemToUserFor, 'HH:mm'));
    if(Before){
      if(isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm'))){
        timeStops.push({
          //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
          value: toTiem,
          label: 'prima possibile',
          isDisabled: active,
          string:toTiem,
          id:id,
          //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
        });
      }else {
        timeStops.push({
          //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
          value: toTiem,
          label: toTiem,
          string:toTiem,
          isDisabled: active,
          id:id,
          //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
        });
      }

    }

    startTime.add(30, 'minutes');
  }
  console.log('timeStops');
  console.log(timeStops);
  return timeStops;
}

function getTimeStopsOld(time,items,type){
  var startTime = moment(time[0], 'HH:mm');
  var bisTime = moment(time[0], 'HH:mm');
  var endTime = moment(time[1], 'HH:mm');

  if( endTime.isBefore(startTime) ){
    endTime.add(1, 'day');
  }

  var timeStops = [];

  while(startTime <= endTime){
    let itemToUserFor =  bisTime.add(30, 'minutes')
    let toTiem = new moment(startTime).format('HH:mm') + '-'+new moment(itemToUserFor).format('HH:mm')
    let id = toTiem.replace(/:/g, '');
    id = id.replace(/-/g, '');
    let ccc = moment().format('HH:mm')

    console.log('mostimao active');
    console.log(items.block_takeA);
    let active = items.block_takeA ? items.block_takeA[id] : false;
    console.log(id);

    //console.log(items.block_daily_p[id]);

    let Before = moment(moment(ccc, 'HH:mm')).isBefore(moment(itemToUserFor, 'HH:mm'));
    if(Before){
      if(isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm'))){
        timeStops.push({
          //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
          value: toTiem,
          label: 'prima possibile',
          isDisabled: active,
          string:toTiem,
          id:id,
          //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
        });
      }else {
        timeStops.push({
          //isDisabled:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
          value: toTiem,
          label: toTiem,
          string:toTiem,
          isDisabled: active,
          id:id,
          //now:isNow(new moment(startTime).format('HH:mm'),new moment(itemToUserFor).format('HH:mm')),
        });
      }

    }

    startTime.add(30, 'minutes');
  }
  console.log('timeStops');
  console.log(timeStops);
  return timeStops;
}




class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
				cart:[],
				carts:null,
        auth:null,
        item:null,
        lontano:false,
        categorie:[],
        struttura:[],
        visible : false,
				delyveryForm:false,
        selected:null,
        type:null,
        indirizzoDelivery:null,
        indirizzoDeliverySelect:null,
				reset:false,
				paga:false,
				loadinPaymant:false,
        option:[],
        optionAsp:[],
    };


  }

  todayIstest(p){
    let {item} = this.state;
    if(item){
      let orari = item.orari;
      let orari_takeA = item.orari_takeA;
      var weekday = new Array(7);
      weekday[0] = "domenica";
      weekday[1] = "lunedì";
      weekday[2] = "martedì";
      weekday[3] = "mercoledì";
      weekday[4] = "giovedì";
      weekday[5] = "venerdì";
      weekday[6] = "sabato";
      let today = new Date()
      console.log('oggi è:');
      let numeberDay = weekday[today.getDay()];

      let orariDays = orari ? orari[numeberDay] : [];
      let orariDaysT = orari_takeA ? orari_takeA[numeberDay] : [];

      console.log('vediamo che succ');
      console.log(orariDays);
      console.log(orariDaysT);


      let Type = 'M';
      if(p == true){
        Type = 'P';
      }

      let arrayYoUse = [];
      let arrayYoUseT = [];
      if(orariDaysT['M']){
        console.log('facci M');
        arrayYoUseT =  arrayYoUseT.concat(getTimeStops(orariDaysT['M'],item,'asp'))
      }
      if(orariDaysT['P']){
        console.log('facci P');
        arrayYoUseT = arrayYoUseT.concat(getTimeStops(orariDaysT['P'],item,'asp'))
      }

      console.log('qui asp slot');
      console.log(arrayYoUseT);

      this.setState({optionAsp:arrayYoUseT})

      console.log('qui errore');
      console.log(orariDays);

      if(orariDays && orariDays['M']){
        console.log('facci M');
        arrayYoUse =  arrayYoUse.concat(getTimeStops(orariDays['M'],item,'delyv'))
      }
      if(orariDays && orariDays['P']){
        console.log('facci P');
        arrayYoUse = arrayYoUse.concat(getTimeStops(orariDays['P'],item,'delyv'))
      }

      this.setState({option:arrayYoUse})

    }
  }

  todayIs(p){
    let {item} = this.state;
    if(item){
      let orari = item.orari;
      var weekday = new Array(7);
      weekday[0] = "domenica";
      weekday[1] = "lunedì";
      weekday[2] = "martedì";
      weekday[3] = "mercoledì";
      weekday[4] = "giovedì";
      weekday[5] = "venerdì";
      weekday[6] = "sabato";
      let today = new Date()
      console.log('oggi è:');
      let numeberDay = weekday[today.getDay()];

      let orariDays = orari[numeberDay];
      console.log(orariDays);


      let Type = 'M';
      if(p == true){
        Type = 'P';
      }

      if(orariDays && orariDays[Type]){
        return (
          <div>
            {getTimeStops(orariDays[Type],item).map((time,i)=>{
              return (<div className={time.now ? 'nowTime' : 'notNow'} style={{padding:10,marginBottom:10,display:'inline-block',marginRight:10,cursor:'pointer'}} key={time.id}>
                        {time.string}
                      </div>)
            })}

          </div>
        )
      }else {
        return null
      }
    }else {
      return null
    }


  }

  openModalAsk(){
      this.setState({
          visibleAsk : true
      });
  }
  openModal(info) {
        this.setState({
            visible : true,
            selected: info
        });
    }

    closeModal() {
        this.setState({
            visible : false,
            selected:null
        });
    }

    closeModalAsk(){
        this.setState({
            visibleAsk : false
        });
    }

  createCardSchema(items){
    let {categorie} = this.state;
    let struttura = [];
    categorie.forEach((categoriaA, i) => {
        let categoria = {
          id:categoriaA._id,
          nome:categoriaA.nome,
          piatti:[],
        }
        items.forEach((piatto, i) => {
          let index = piatto.categorie.indexOf(categoriaA._id)
          if(index >-1){
            categoria.piatti.push(piatto);
          }
        });
        if(categoria.piatti.length > 0){
          struttura.push(categoria)
        }

    });
    this.setState({struttura})

  }

	Total(cart){
let subTotal = 0;
		cart.items.forEach((x,i)=>{

			subTotal = subTotal + (x.qty * x.prezzo);
		})
		return subTotal
	}

	GetcartS(id){
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.cart
    .getOne({id:id},ppp)
    .then(res=>{

      this.setState({carts:res.data});
    });
  }

  CreatecartS(){
		const cookies = new Cookies();
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};

		var obj = {}
		var oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    ApiTags.endpoints.cart
    .create(obj,ppp)
    .then(res=>{

      this.setState({carts:res.data});
			cookies.set('roo_cagui', res.data._id, { path: '/' , expires:oneYearFromNow});
    });
  }


  getRist(){
    //usa per il get
    const ApiTags= new API ({url:'/ristorante'});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.ristorante
    .getOne({id:idP},ppp)
    .then(res=>{
      this.setState({item:res.data});
      this.todayIstest();
      this.getCatgorie(res.data);
    });
  }

  getCatgorie(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyrist'});
    var entity= {name:"categoriabyrist"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.categoriabyrist
    .getOne({id:x._id},ppp)
    .then(res=>{
      this.setState({categorie:res.data})
      this.getPiatti()
    });
  }




  getPiatti(){
    const ApiTags= new API ({url:'/findbyrist'});
    var entity= {name:"findbyrist"};
    ApiTags.createEntity(entity);
      var ppp = {};
      ApiTags.endpoints.findbyrist
      .getOne({id:idP},ppp)
      .then(res=>{

          this.createCardSchema(res.data)
        });
  }


  componentDidMount() {
		//document.body.style.overflow = "hidden"
    window.scrollTo(0,0);
		const cookies = new Cookies();
		if(cookies.get('roo_cagui')){
			this.GetcartS(cookies.get('roo_cagui'))
		}else {
			this.CreatecartS()

		}

    this.getRist()
    let newThis = this;
    this.props.firebase.onAuthUserSpecial(function(user) {

        if(user){
          let auth = user;


          newThis.setState({auth:auth})
        }
    })


  }

  openPopType(){
    let {carts} = this.state;

    if(carts && carts.type){
      switch (carts.type) {
        case 'take_away':
          return true;
        default:
          if(carts.indirizzo){
            return true
          }
      }
    }else {
      return false
    }

  }

  cardPiatto(piatti){
    return piatti.forEach((piatto,i)=>{
      return(
        <div onClick={!this.openPopType() ? ()=>this.openModalAsk() : ()=>this.openModal(piatto)} key={i} style={{display:'flex'}} className='cardList'>
          <div style={{padding: 10,paddingTop: 0}}>
            <h4 style={{marginTop:0}}>{piatto.nome}</h4>
          <div className='f1'>
            <div >{piatto.desc}</div>
          </div>
					<div style={{marginTop:10}} className='f1'>
            <div >{piatto.prezzo} €</div>
          </div>
          </div>
          <div style={{ marginLeft: 'auto'}}>
            {piatto.img ? <img style={{width:100,objectFit: 'cover',height:100}} src={piatto.img} alt={piatto.nome}/> : null}
          </div>
        </div>
      )
    })
  }

  calculateDist(geoMy){
    let {item} = this.state;
    let c1 = item.areaLavoro.coordinates

    let c2 = geoMy.geometry.coordinates

    let distancePosy = distance(c1[0],c1[1],c2[0],c2[1],'N');
    if(distancePosy > 1){
      this.setState({lontano:true})
    }else {
      this.setState({lontano:false,indirizzoDelivery:geoMy})
    }


  }


subTotal(sped){
	let {carts,item} = this.state;
	let subTotal = 0;
  if(carts){
    if(carts && carts.type != 'take_away'){
      carts.items.forEach((x,i)=>{

        if(x.qty && x.qty > 0){
            if(x.sconto > 0){
              subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.sconto/100);
            }else {
              subTotal = subTotal + (x.qty * x.prezzo);
            }

        }

      })
    }else {
      carts.items.forEach((x,i)=>{

        if(x.qty && x.qty > 0){
            if(x.scontoAsp > 0){
              subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.scontoAsp/100);
            }else {
              subTotal = subTotal + (x.qty * x.prezzo);
            }

        }

      })
    }
  }


	/*if(carts){
		carts.items.forEach((x,i)=>{

			if(x.qty && x.qty > 0){
        subTotal = subTotal + (x.qty * x.prezzo);
      }

		})
	}*/
  if(carts && carts.type != 'take_away'){
    if(sped){
  		subTotal = subTotal +Number(item ? item.prezzoConsegna : 0)
  	}
  }


	return decimal(subTotal)
}

TotalP(){
	let {carts,item} = this.state;
	let subTotal = 0;

  if(carts){
    if(carts && carts.type != 'take_away'){
      carts.items.forEach((x,i)=>{

        if(x.qty && x.qty > 0){
            if(x.sconto > 0){
              subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.sconto/100);
            }else {
              subTotal = subTotal + (x.qty * x.prezzo);
            }

        }

      })
    }else {
      carts.items.forEach((x,i)=>{

        if(x.qty && x.qty > 0){
            if(x.scontoAsp > 0){
              subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.scontoAsp/100);
            }else {
              subTotal = subTotal + (x.qty * x.prezzo);
            }

        }

      })
    }

	}
  if(carts.type != 'take_away'){
    subTotal = Number(subTotal)+Number(item.prezzoConsegna)
  }

	return subTotal
}


paga(){
	let {auth,carts} = this.state
	if(auth.telefono && carts.orario){
		this.setState({paga:true})
    window.scrollTo(0, 0);
	}else {
    alert('controlla di aver inserito tutti i campi obbligatori')
  }
}

cambia(type,value){
	let {carts} = this.state;
	carts[type] = value;
	this.setState({carts})
}


CreaOrdine(detailsPaypal,dataPaypal){
		let {auth,carts,item} = this.state;
      console.log(auth);
      let obj = {};
    if(detailsPaypal && dataPaypal){
      obj = {
  			"id" : carts._id,
  			"cliente" : auth.id,
        "typeO":carts.type,
        "nome_cliente":auth.nome,
  			"rifRistor": idP,
  			"status": "ordine",
  			"piano": carts.piano,
  		  "citofono":carts.citofono,
  			"paymanType":"pay_pal",
  			"statusPay":'ok',
  			"StatusOrdine":'new',
  			"detailsPaypal":detailsPaypal,
  		  "dataPaypal":dataPaypal,
        "dataPaypal":dataPaypal,
        "orario":carts.orario,
        "order_made_time":new Date().getTime(),
        "idTelegram":item.idTelegram,
  		}
    }else {
      obj = {
        "typeO":carts.type,
  			"id" : carts._id,
  			"cliente" : auth.id,
  			"rifRistor": idP,
        "nome_cliente":auth.nome,
  			"status": "ordine",
  			"piano": carts.piano,
  		  "citofono":carts.citofono,
  			"paymanType":"consegna",
  			"StatusOrdine":'new',
        "orario":carts.orario,
        "order_made_time":new Date().getTime(),
        "idTelegram":item.idTelegram,
  		}
    }

    if(carts.type == 'delivery'){
      obj.coordinates = carts.indirizzo.coordinates;
    }


    const ApiTags= new API ({url:'/ordinacart'});
  	var entity= {name:"ordinacart"};
  	ApiTags.createEntity(entity);
  	var ppp = {};

  	ApiTags.endpoints.ordinacart
  	.update(obj,ppp)
  	.then(res=>{

			this.setState({loadinPaymant:true})

      this.props.history.push(ROUTES.MENU);

  	});

}

/*
todayIs(p){
    let {item} = this.state;
    var weekday = new Array(7);
    weekday[0] = "domenica";
    weekday[1] = "lunedì";
    weekday[2] = "martedì";
    weekday[3] = "mercoledì";
    weekday[4] = "giovedì";
    weekday[5] = "venerdì";
    weekday[6] = "sabato";
    let today = new Date()
    console.log('oggi è:');
    let numeberDay = weekday[today.getDay()];

    let orariDays = item.orari[numeberDay];
    console.log(orariDays);


    let Type = 'M';
    if(p == true){
      Type = 'P';
    }

    if(item && item.orariDays && item.orariDays[Type]){
      return (
        <div>
          {getTimeStops(orariDays[Type]).map((time,i)=>{
            return (<div className={time.now ? 'nowTime' : 'notNow'} style={{padding:10,marginBottom:10,display:'inline-block',marginRight:10,cursor:'pointer'}} key={time.id}>
                      {time.string}
                    </div>)
          })}
        </div>
      )
    }else {
      return null
    }

  }
*/
typeSpedizione(){
	let {carts,auth,option,optionAsp} = this.state;
	if(carts){
		switch (carts.type) {
			case "take_away":
			return(
				<div style={{background:'white',marginTop:30,padding:15,borderRadius:3}}>
					<div className='inputD'>
						<div>
							<label>Nome</label>
							<input value={auth ? auth.nome : null} onChange={(x)=>this.cambia('tel',x.target.value)} />
						</div>
						<div>
							<label>Numero di telefono</label>
							<input value={auth ? auth.telefono : null} onChange={(x)=>this.cambia('tel',x.target.value)} />
						</div>
					</div>
					<div style={{padding:10}} className=''>
						<div>
							{optionAsp.length > 0 ? ( <label style={{marginBottom:10}}>Qundo vuoi passare?</label> ) : null}
                <div   style={{marginTop:20}}>
                  {optionAsp.length > 0 ? (
                    <Select
                      onChange={(x)=>this.cambia('orario',x.value)}
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={'ppp'}
                      name="color"
                      options={optionAsp}
                    />
                  ) : (
                    <div>
                      <p>Ci scusiamo ma il servizio di asporto per oggi è terminato torna a trovarci domani</p>
                    </div>
                  )}

                </div>
						</div>
            {/*this.todayIs()}
            {this.todayIs(true)*/}
					</div>
				</div>
			);
			default:
			return(
				<div style={{background:'white',marginTop:30,padding:15,borderRadius:3}}>
					<div className='inputD'>
						<div>
							<label>Piano e interno</label>
							<input value={carts ? carts.piano : null} onChange={(x)=>this.cambia('piano',x.target.value)} />
						</div>
						<div>
							<label>Citofono</label>
							<input value={carts ? carts.citofono : null} onChange={(x)=>this.cambia('citofono',x.target.value)} />
						</div>
					</div>
					<div className='inputD'>
						<div>
							<label>Indirizzo</label>
							<input value={carts ? carts.IndirizzoVia+' '+carts.address : null}  />
							<sub>Inserisci l'indirizzo e il numero civico.</sub>
						</div>
					</div>
					<div className='inputD'>
						<div>
							<label>CAP</label>
							<input value={carts ? carts.cap : null} />
						</div>
						<div>
							<label>Città</label>
							<input value={carts ? carts.citta : null} />
						</div>
					</div>
					<div className='inputD'>
						<div>
							<label>Numero di telefono</label>
							<input value={auth ? auth.telefono : null} onChange={(x)=>this.cambia('tel',x.target.value)} />
						</div>
					</div>
          <div style={{padding:10}} className=''>
						<div>
							<label style={{marginBottom:10}}>Orario di consegna</label>
                <div   style={{marginTop:20}}>
                  <Select
                    onChange={(x)=>this.cambia('orario',x.value)}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={'ppp'}
                    name="color"
                    options={option}
                  />
                </div>
						</div>
            {/*this.todayIs()}
            {this.todayIs(true)*/}
					</div>
				</div>
			)
		}
	}else {
		return(
			<p>jj</p>
		)
	}



}

textUse(type){
	switch (type) {
		case 'take_away':
			return 'ordina da aporto';
		default:
			 return 'ordina a domicili';
	}
}


selectPayment(type){
  let {carts,item} = this.state;
  if(type == 'take_away'){
    if(item.payPal_takeA && item.contanti_Alla_consegna_takeA){
      return (
        <div>
          <div>
            <p>Paga con Paypal</p>
            <PayPalButton
  							shippingPreference='NO_SHIPPING'
  							amount={TotalP(carts,item)}

  							onSuccess={(details, data) => {
  								this.setState({loadinPaymant:true})
  								//alert("Transaction completed by " + details.payer.name.given_name);
  								//console.log(details);
  								//console.log(data);

  								this.CreaOrdine(details, data);
  								// OPTIONAL: Call your server to save the transaction
  								/*return fetch("/paypal-transaction-complete", {
  									method: "post",
  									body: JSON.stringify({
  										orderID: data.orderID
  									})

  									"ATvw-INv6YwISTxEF3mveUQuXI3ZHOZYwqtLQcK9lVSb7lXOTrPcfAiJ_Obgbr4yeEKJdG7yvT83YHMZ"
  								});*/
  							}}
  							options={{
  									clientId: idT,
  									disableFunding:"sofort,mybank",
  									currency:"EUR"
  								}}
  						/>
          </div>
          <div>
            <h2>o</h2>
          </div>
          <div>
            <sub>modalita di pacamento accetate : </sub>
            <p>Paga comodamente alla consegna del cibo</p>

            <div>
              <button onClick={()=>this.CreaOrdine()} style={{background:'black'}} className='buttonAction'>Pagamento alla consegna</button>
              <p>Paga comodamento al ritiro </p>
            </div>
          </div>
        </div>
      )
    }
    if(item.payPal_takeA){
      return (
        <p>ok paga paypal</p>
      )
    }
    if(item.contanti_Alla_consegna_takeA){
      return (
        <div>
          {/*<{Stripe ammont={TotalP(carts,item)} />*/}
          <button onClick={()=>this.CreaOrdine()} style={{background:'black'}} className='buttonAction'>Pagamento alla consegna</button>
          <p>Paga comodamento al ritiro </p>
        </div>
      )
    }
  }else {
    if(item.payPal && item.contanti_Alla_consegna){
      return (
        <div>
          <div>
            <p>Paga con Paypal</p>
            <p>{this.TotalP()}</p>
            <PayPalButton
  							shippingPreference='NO_SHIPPING'
  							amount={this.TotalP()}

  							onSuccess={(details, data) => {
  								this.setState({loadinPaymant:true})
  								//alert("Transaction completed by " + details.payer.name.given_name);
  								//console.log(details);
  								//console.log(data);

  								this.CreaOrdine(details, data);
  								// OPTIONAL: Call your server to save the transaction
  								/*return fetch("/paypal-transaction-complete", {
  									method: "post",
  									body: JSON.stringify({
  										orderID: data.orderID
  									})

  									"ATvw-INv6YwISTxEF3mveUQuXI3ZHOZYwqtLQcK9lVSb7lXOTrPcfAiJ_Obgbr4yeEKJdG7yvT83YHMZ"
  								});*/
  							}}
  							options={{
  									clientId: idT,
  									disableFunding:"sofort,mybank",
  									currency:"EUR"
  								}}
  						/>
          </div>
          <div>
            <h2>o</h2>
          </div>
          <div>
            <sub>modalita di pacamento accetate : </sub>
            <p>Paga comodamente alla consegna del cibo</p>

            <div>
              <button onClick={()=>this.CreaOrdine()} style={{background:'black'}} className='buttonAction'>Pagamento alla consegna</button>
              <p>Paga comodamento al ritiro </p>
            </div>
          </div>
        </div>
      )
    }
    if(item.payPal){
      return (
        <p>ok paga paypal</p>
      )
    }
    if(item.contanti_Alla_consegna){
      return (
        <p>ok paga contanti</p>
      )
    }
  }
}


modalitadiPagamento(){
	let {item,carts} = this.state;



	if(item && item.typeFlow === 'whatp'){
		if(item && item.payPal){
			return(
				<div>

				<div style={{padding:20,background:'white',marginTop:20,marginBottom:30}}>
					Il pagamento sarà effettuato tramite paypal.
					Alla ricezione dell'ordine su whatsapp ti manderemo sullo stesso numero un link con il carrello e pagamento payPal
				</div>
				</div>
			)
		}else {
      if(carts.type == 'take_away'){
        return (
  				<div style={{padding:20,background:'white',marginTop:20,marginBottom:30}} >
  					Puoi pagare tranquillamente al momento del ritiro
  				</div>
  			)
      }else {
        return (
  				<div style={{padding:20,background:'white',marginTop:20,marginBottom:30}} >
  					Puoi pagare tranquillamente al momento della consegna tramite contanti, carta o bancomat
  				</div>
  			)
      }

		}
	}else {
    return this.selectPayment(carts.type)


  }



}

sum(qty,prezzo){
	let fine = qty*prezzo;
	return decimal(fine)
}

createText() {
	let {carts,auth,item} = this.state;
	let lista = ''
	let totale  = 0;
  let totaleT  = 0;
	if(carts){
		carts.items.forEach((item, i) => {
			totale = totale+item.qty*item.prezzo;
      if(item.qty > 0){
        lista = lista+'\r\ '+item.qty+' x '+' '+item.nome+' ('+this.sum(item.qty,item.prezzo)+''+' euro) \r\ ';

      }
		});

	}

  if(carts.type == 'take_away'){
    totaleT =totale+Number(item ?item.prezzoConsegna : 0);
    var text = "Ciao ecco il mio ordine da asporto:";
  	text = text+'\r\ ';
  	text = text+lista;
  	text = text+'\r\______________________\r\ ';
  	text = text+'\r\ totale ordine '+totale+' Euro\r\ ';
  	text = text+'\r\ Contatto - '+auth.nome;
  	text = text+'\r\ passo io!';
  	text = text+'\r\ ';
  }else {
    totaleT =totale+Number(item ?item.prezzoConsegna : 0);
    var text = "Ciao ecco il mio ordine:";
  	text = text+'\r\ ';
  	text = text+lista;
  	text = text+'\r\______________________\r\ ';
  	text = text+'\r\ Subtotale ordine '+totale+' Euro\r\ ';
    text = text+'\r\ Costo spedizione '+(item ? item.prezzoConsegna : 0)+' Euro\r\ ';
    text = text+'\r\ Totale '+totaleT+' Euro\r\ ';
  	text = text+'\r\ Contatto - '+auth.nome;
  	text = text+'\r\ Indirizzo spedizione - '+(carts ? carts.indirizzoCompleto : null);
  	text = text+'\r\ ';
  	text = text+'\r\ http://maps.google.com/maps?q='+escape(carts.indirizzoCompleto);
  }


  var res = escape(text);
  return res
}

textMessgenerator(){
	/*
	'Ciao%21+ecco+il+mio+ordine%3A+%0D%0Aio+sono+luca+bla%0D%0A%0D%0A%0D%0Apollo%0D%0A%0D%0A%F0%9F%8D%9D+Ordine%0D%0A2+x+1+Riso%2C+Tempura+di+gamberi+%288.00%E2%82%AC%29%0D%0A4+x+2+Riso%2C+Cetriolo%2C+Alghe%2C+Fagioli+verdi%2C+Mais%2C+Carota%2C+Avocado+%288.00%E2%82%AC%29%0D%0A4+x+3+Riso%2C+Salmone%2C+Mais%2C+Fagioli+verdi%2C+Avocado+%288.00%E2%82%AC%29%0D%0ATotale+presunto+ordine%3A+80.00%E2%82%AC%0D%0A%0D%0A%0D%0A%F0%9F%91%A4Nome%0D%0Agjhgj%0D%0A%0D%0A%F0%9F%93%8DIndirizzo%0D%0Amjhjkgjg%0D%0A%0D%0A%0D%0A%F0%9F%95%90+Orario+richiesto%0D%0Akghjhgjhgj%0D%0A%0D%0A%0D%0A%F0%9F%93%9DNote%0D%0Aghjjhgjjhgj%0D%0A'
	*/
	let {item} = this.state;
	let text = this.createText();
	return 'https://api.whatsapp.com/send?phone='+item.whatupNumber.replace("+", "")+'&text='+text
}

ordinaAction(){
	let {item,carts} = this.state;



	if(item && item.typeFlow === 'whatp'){
		if(item && item.payPal){
			return(
				<div><h3>Invia Ordine tramite whatsapp</h3>
				{carts ? <p>{this.textUse(carts.type)}</p> : null }
				<div style={{padding:20,background:'white',marginTop:20,marginBottom:30}}>
					<p>compila tutti i campi richiesti e inviaci l'ordine direttamente su whatsapp. Ti risponderemo il prima possibile per confermarti la consegna!</p>

				</div>
				<a target='_blank' rel="noopener noreferrer" href={this.textMessgenerator()}>
				<button style={{width:'100%',padding:15,border:'none',borderRadius:4,color:'white',background:'#00bfa5', fontSize:14,fontWeight:800,cursor:'pointer'}}>
					Ordina tramite whatsapp
				</button></a>
				</div>
			)
		}else {
			return (
				<a target='_blank' rel="noopener noreferrer" href={this.textMessgenerator()}>
				<button style={{width:'100%',padding:15,border:'none',borderRadius:4,color:'white',background:'#00bfa5', fontSize:14,fontWeight:800,cursor:'pointer'}}>
					Ordina tramite whatsapp
				</button></a>
			)
		}
	}else {
		if(item && item.payPal){
			return(
				<div>
					{/*<div style={{marginTop:20}}><button  onClick={()=>this.paga()} className='buttonAction' >Ordina per la consegna</button></div>*/}
				</div>
			)
		}else {
			return (
				<div style={{marginTop:10}}>
					puoi pagare tranquillamente quando passi a prendere il tuo ordine
				</div>
			)
		}
	}


}

pricePiatto(piatto){

  let {carts} = this.state;
  let type = carts.type;
  let totalP = piatto.qty * piatto.prezzo;
  if(type == 'take_away'){
    if(piatto.scontoAsp > 0){
      return <span><span style={{textDecoration: 'line-through'}}>{decimal(totalP)}€</span><span style={{color:'orange',marginLeft:5}}>{decimal(totalP - (totalP*piatto.scontoAsp/100))}€</span></span>
    }else {
      return decimal(totalP)
    }

  }else {
    if(piatto.sconto > 0){
      return <span><span style={{textDecoration: 'line-through'}}>{decimal(totalP)}€</span><span style={{color:'orange',marginLeft:5}}>{decimal(totalP - (totalP*piatto.sconto/100))}€</span></span>
    }else {
      return decimal(totalP)
    }
  }

}

async getInformr(){
    let res = await axios.get("https://rivolta-api-server.herokuapp.com/api/v1/stripeorder");
    let { data } = res.data;
    console.log('mostroooo');
    console.log(res.data);
    return res.data.id
  };

async handleClick(){
      /*const ApiTags= new API ({url:'/ristorantipayment/'});
      var entity= {name:"ristorantipayment"};
      var ppp = {'Authorization' : 'Bearer dsdsds'};*/
      const sessions = await this.getInformr();
      console.log('sessionId');
      console.log(sessions);
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId:sessions,
      });
  }


render() {
    let {loadinPaymant,paga,carts, auth,item,optionAsp} = this.state;
    return (
      <div style={{}}>
				<Helmet>
            <meta charSet="utf-8" />
            <title>{item ? item.nome+' - Check-out' : 'Check-out'}</title>
        </Helmet>
				{loadinPaymant ? (
					<div style={{position:'fixed',background:'#eaeaea47',left:0,right:0,bottom:0,top:0,zIndex:10}}>
						<div style={{marginLeft:'auto',marginRight:'auto',background:'white',width:400,padding:20,borderRadius:3,marginTop:'20%'}}>
							<h3>Stiamo procedendo con la creazione dell'ordine</h3>
							<p>non chiudere la pagina...</p>
						</div>
					</div>
				) : null}

          <Header auth={auth} item={item}  carts={carts} />
        <div className=''>
          <div className=''></div>

          <div className='container restaurant' >
            <div style={{background:'transparent',marginTop:50}} className='containerFix grid03'>

              <div style={{minHeight:600}} className='info'>
								<h1 style={{marginBottom:0,textTransform:'capitalize',color:'white'}}>{item ? item.nome : null}</h1>
									{carts && carts.type === 'take_away' ? <p style={{marginTop:10,fontWeight:800,color:'white'}}>Ordine da asporto</p> : <p style={{marginTop:10,fontWeight:800,fontSize:16,color:'white'}}>Ordine a domicilio</p>}
                {  /*qui flusso paga*/}
                  {paga ? (
										<div style={{background:'white',marginTop:30,padding:20,borderRadius:3}}>
											{ carts.type === 'take_away' ? (
												null
											) : (
												<div style={{marginBottom:30,marginTop:10}}>
													<h3 style={{marginBottom:30}}>Indirizzo di consegna</h3>
													<p onClick={()=>this.setState({paga:false})} className='colorp' style={{marginLeft:'auto',cursor:'pointer'}}>cambia</p>
													<div style={{border:'1px solid #ddd',borderRadius:5,padding:10}}>
														<p>{carts ? carts.indirizzoCompleto : null}</p>
														<p>{carts ? auth.telefono : null}</p>
														<hr></hr>
														<p>citofono: {carts ? carts.citofono : null}</p>
														<p>interno/ piano: {carts ? carts.piano : null}</p>
													</div>
												</div>
											)}
											<div style={{marginBottom:30}}>




												<h3>Procedi con il pagamento</h3>
                        <p>Qui trovi le modalità di pagamento, finita la procedura verrai rimandato al menu dove troverai lo storico delle tue ordinazioni e potrai controllarne lo stato.</p>
											</div>

                        {this.modalitadiPagamento()}
												{this.ordinaAction()}
										</div>

									) : (
										<div>
											{this.typeSpedizione()}
										<div style={{marginTop:30}}>

                        {optionAsp.length > 0 ? (
                          <div>
                            <h3></h3>
                            <div style={{marginTop:20}}><button  onClick={()=>this.paga()} className='buttonAction' >continua</button></div>

                          </div>
                        )   : null }



										</div>
										</div>
									)}


							</div>
							<div className='cartCk' >
								<div style={{background:'white',padding:20,borderRadius:3}}>
									<p style={{fontSize:20,fontWeight:700}}>Carrello</p>
                  <Cart showMode={true} close={() => console.log('pollo')} auth={auth} speseSped={item ?item.prezzoConsegna : null} cart={carts} />


								</div>

							</div>
						</div>
					</div>
				</div>



				<div className='footr'>
						<Footer />
				</div>
			</div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
	withAuthorization(condition),
)(HomePage);
