import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import API from "../../util/Api.js"

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './index.css'
var idP = process.env.REACT_APP_RESTORANT_TOKEN;
var OrgToken = process.env.REACT_APP_ORG_TOKEN;

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  telefono:'',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne ,telefono } = this.state;
    const roles = [];


    if(username !== '' && email  !== '' && passwordOne  !== '' && telefono  !== ''){
      this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {

      const ApiTags= new API ({url:'/cliente'});
      var entity= {name:"cliente"};
      ApiTags.createEntity(entity);
      var ppp = {};

  		var obj = {
        nome:username,
        uid:authUser.user.uid,
        email:email,
        privicyAc:true,
        telefono:telefono,
        rifRistor:idP,
        rifOrganiz:OrgToken,
      }
  		ApiTags.endpoints.cliente
      .create(obj,ppp)
      .then(res=>{

        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LANDING);

      });
        // Create a user in your Firebase realtime database
        /*return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles,
        });*/
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
    }else{
      alert('devi inserire tutti i campi per la registrazione')
    }
  };

  /*.then(() => {
    return this.props.firebase.doSendEmailVerification();
  })*/

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      telefono,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
          <label>Nome completo</label>
        </div>
        <input
          style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Nome completo"
        />
        <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
          <label>Email</label>
        </div>
        <input
          name="email"
          style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
          <label>Numero Telefono (usa il numero di whatsapp)</label>
        </div>
      <div className='phoneForm'>
          <PhoneInput
            country={'it'}
            type="tel"
            value={this.state.telefono}
            onChange={phone => this.setState({ telefono:phone })}
          />
        </div>

        {/*<input
          pattern="[0-9]{10}" required
          name="telefono"
          style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
          value={telefono}
          onChange={this.onChange}
          type="tel"
          placeholder="Numero di telefono"
        />*/}
        <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
          <label>password</label>
        </div>
        <input
          style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
          <label>Conferma Password</label>
        </div>
        <input
          style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />
      <div style={{maxWidth:400,marginLeft:'auto',marginRight:'auto'}}><button style={{marginLeft:0}} className='buttonAction' disabled={isInvalid} type="submit">
          Registrati
        </button></div>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Non sei registrato? <Link to={ROUTES.SIGN_UP}>Registrati</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
