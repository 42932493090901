import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import asyncComponent from "../AsyncComponent";


import HomePagePages from '../Home';
import checkOutPage from '../CheckOut';
import menuPage from '../Menu';
import Privacy from '../Privacy';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import withTracker from './withTracker'
import * as ROUTES from '../../constants/routes';

import { withAuthentication } from '../Session';
/*
const HomePagePages = asyncComponent(() => import("../Home"));
const checkOutPage = asyncComponent(() => import("../CheckOut"));
const menuPage = asyncComponent(() => import("../Menu"));
const SignUpPage = asyncComponent(() => import("../SignUp"));
const SignInPage = asyncComponent(() => import("../SignIn"));*/



const App = () => (
  <Router>
    <div>
    <Route path={ROUTES.SIGN_UP} component={withTracker(SignUpPage)} />
    <Route path={ROUTES.SIGN_IN} component={withTracker(SignInPage)} />
    <Route exact path={ROUTES.LANDING} component={withTracker(HomePagePages)} />
    <Route exact path={ROUTES.CHECKOUT} component={withTracker(checkOutPage)} />
    <Route exact path={ROUTES.MENU} component={withTracker(menuPage)} />
    <Route exact path={ROUTES.PRIVACY} component={withTracker(Privacy)} />
    </div>
  </Router>
);

export default withAuthentication(App);
