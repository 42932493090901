import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import Footer from '../Footer';
import Logo from '../img/logoRis.jpeg';
import Header from '../Header';
import {TotalP,stampto,statusConvert} from "../../util/functions.js"
import { Modal } from 'react-responsive-modal';
import Cart from '../Cart';

var idP = process.env.REACT_APP_RESTORANT_TOKEN;

function decimal(num) {
  var n = num.toFixed(2);
  return n
}



class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
				cart:[],
				carts:null,
        auth:null,
        item:null,
        lontano:false,
        categorie:[],
        struttura:[],
        visible : false,
				delyveryForm:false,
        selected:null,
        type:null,
        indirizzoDelivery:null,
        indirizzoDeliverySelect:null,
				reset:false,
        storico:[],
        storicoPreno:[],
        open:false,
        orineSelect:null,
        prenoSelect:null,
        selezione:0,      
    };


  }

  openModalAsk(){
      this.setState({
          visibleAsk : true
      });
  }
  openModal(info) {
        this.setState({
            visible : true,
            selected: info
        });
    }

    closeModal() {
        this.setState({
            visible : false,
            selected:null
        });
    }

    closeModalAsk(){
        this.setState({
            visibleAsk : false
        });
    }

  createCardSchema(items){
    let {categorie} = this.state;
    let struttura = [];
    categorie.forEach((categoriaA, i) => {
        let categoria = {
          id:categoriaA._id,
          nome:categoriaA.nome,
          piatti:[],
        }
        items.forEach((piatto, i) => {
          let index = piatto.categorie.indexOf(categoriaA._id)
          if(index >-1){
            categoria.piatti.push(piatto);
          }
        });
        if(categoria.piatti.length > 0){
          struttura.push(categoria)
        }

    });
    this.setState({struttura})

  }

	Total(cart){
		let subTotal = 0;
		cart.items.forEach((x,i)=>{
			subTotal = subTotal + (x.qty * x.prezzo);
		})
		return subTotal
	}

	GetcartS(id){
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.cart
    .getOne({id:id},ppp)
    .then(res=>{

      this.setState({carts:res.data});
    });
  }

  CreatecartS(){
		const cookies = new Cookies();
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};

		var obj = {}
		var oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    ApiTags.endpoints.cart
    .create(obj,ppp)
    .then(res=>{

      this.setState({carts:res.data});
			cookies.set('roo_cagui', res.data._id, { path: '/' , expires:oneYearFromNow});
    });
  }


  getRist(){
    //usa per il get
    const ApiTags= new API ({url:'/ristorante'});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.ristorante
    .getOne({id:idP},ppp)
    .then(res=>{
      this.setState({item:res.data});
      this.getCatgorie(res.data);
    });
  }

  getCatgorie(x){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabyrist'});
    var entity= {name:"categoriabyrist"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.categoriabyrist
    .getOne({id:x._id},ppp)
    .then(res=>{
      this.setState({categorie:res.data})
      this.getPiatti()
    });
  }




  getPiatti(){

    const ApiTags= new API ({url:'/findbyrist'});
    var entity= {name:"findbyrist"};
    ApiTags.createEntity(entity);
      var ppp = {};
      ApiTags.endpoints.findbyrist
      .getOne({id:idP},ppp)
      .then(res=>{

          this.createCardSchema(res.data)
        });
  }

	getOrdiniRist(id){
		const ApiTags= new API ({url:'/oridifattiperrist'});
		var entity= {name:"oridifattiperrist"};
		ApiTags.createEntity(entity);
			var ppp = {};
			ApiTags.endpoints.oridifattiperrist
			.getAllMulty({id:id,idr:idP},ppp)
			.then(res=>{

					this.setState({storico:res.data})

				});
  }
  
  getPrenoRist(id){
		const ApiTags= new API ({url:'/prenotazioniperrist'});
		var entity= {name:"prenotazioniperrist"};
		ApiTags.createEntity(entity);
			var ppp = {};
			ApiTags.endpoints.prenotazioniperrist
			.getAllMulty({id:id,idr:idP},ppp)
			.then(res=>{

					this.setState({storicoPreno:res.data})

				});
  }

  

  componentDidMount() {
		const cookies = new Cookies();
		if(cookies.get('roo_cagui')){
			this.GetcartS(cookies.get('roo_cagui'))
		}else {
			this.CreatecartS()

		}


    let newThis = this;
    this.props.firebase.onAuthUserSpecial(function(user) {

        if(user){

          let auth = user;



          newThis.getOrdiniRist(auth.id)
          newThis.getPrenoRist(auth.id)
          newThis.setState({auth:auth})
        }
    })


  }




subTotal(value){
  let {carts ,item} = this.state;
	let subTotal= 0;
  if(value){
    value.items.forEach((x,i)=>{

      if(x.qty && x.qty > 0){
        subTotal = subTotal + (x.qty * x.prezzo);
      }
    })
    if(value && value.type != 'take_away'){
      subTotal = subTotal +Number(item ? item.prezzoConsegna : 0)
    }
  }else {
    if(carts){
  		carts.items.forEach((x,i)=>{

  			if(x.qty && x.qty > 0){
          subTotal = subTotal + (x.qty * x.prezzo);
        }
  		})
      if(carts && carts.type != 'take_away'){
        subTotal = subTotal +Number(item ? item.prezzoConsegna : 0)
      }
  	}
  }



	return decimal(subTotal)
}


logOut(){

	this.props.firebase
		.doSignOut();
};


orderType(type){
  switch (type) {
    case 'take_away':
      return 'Da asporto';
    case 'deliveri':
      return 'Consegna a casa';
    default:
      return 'Consegna a casa';
  }
}


cambia(type,value){
	let {carts} = this.state;
	carts[type] = value;
	this.setState({carts})
}

getStatusSync(Ordine,preno){
  let self = this;
  if(preno){
    this.props.firebase.prnotazione(Ordine._id).on('value', function(snapshot) {
      console.log(snapshot.val());
      Ordine.status = statusConvert(snapshot.val().status)
      self.setState({open:true,prenoSelect:Ordine})
    })
  }else{
    this.props.firebase.ordine(Ordine._id).on('value', function(snapshot) {
      console.log(snapshot.val());
      Ordine.status = statusConvert(snapshot.val().status)
      self.setState({open:true,orineSelect:Ordine})
    })
  }
  
}

render() {
    let {prenoSelect,selezione,storicoPreno,storico, auth,item,carts,open,orineSelect} = this.state;
    return (
      <div>
        <Header auth={auth} item={item}  carts={carts} />
        <div className=''>
          <div className=''></div>
          <Modal open={open} onClose={() => this.setState({open:false,orineSelect:null,prenoSelect:null})}>
            {prenoSelect ? (
                <div>
                <h2>Dettaglio Prenotazione</h2>
                <p style={{fontWeight:300}}> Stato della tua prenotazione in tempo reale , per momitorare lo stato della tua prenotazione rimani su questa pagina</p>
                <p className='tagS'>{prenoSelect.status}</p>
               

              </div>
            ) : null }
            {orineSelect ? (
              <div>
                <h2>Dettaglio Ordine</h2>
                <p style={{fontWeight:300}}> Stato del tuo ordine in tempo reale , per momitorare lo stato del tuo ordine rimani su questa pagina</p>
                <p className='tagS'>{orineSelect.status}</p>
                <Cart showMode={true} close={() => console.log('pollo')} auth={auth} speseSped={item ?item.prezzoConsegna : null} cart={orineSelect} />

              </div>
            ) : null}

          </Modal>
          <div className='container restaurant' >
            <div style={{background:'transparent',marginTop:50,maxWidth:600}} className='containerFix'>
              <div style={{minHeight:600}} className='info'>
								<h1 style={{marginBottom:0,textTransform:'capitalize',color:'white'}}>{item ? item.nome : null}</h1>
									<div>
									<div style={{background:'white',marginTop:30,padding:15,borderRadius:3}}>
										<div>
                      <div className='inputD'>
											<div>
												<label>Nome</label>
												<input value={auth ? auth.nome : null} onChange={(x)=>this.cambia('piano',x.target.value)} />
											</div>
										</div>
										<div className='inputD'>
											<div>
												<label>Email</label>
												<input value={auth ? auth.email : null} />
											</div>
											<div>
												<label>Cellulare</label>
												<input value={auth ? auth.telefono : null} />
											</div>
										</div>
										<br></br>
									</div>
                  </div>
									{/*<div style={{marginTop:30}}>
										<h3>Indirizzi</h3>
										<div>
										</div>

									</div>*/}
									<div style={{marginTop:30}}>
										<div style={{display:'flex'}}>
                      <h3 onClick={()=>this.setState({selezione:0})} style={{color: selezione == 0 ? '#000' : '#ddd' }} >Ordini</h3>
                      <h3 onClick={()=>this.setState({selezione:1})} style={{color: selezione == 1 ? '#000' : '#ddd', marginLeft:10 }} >Prenotazioni</h3>
                    </div>
                    {selezione == 0 ? (
                      <div style={{marginTop:20}}>
											{storico.map((ordine,i)=>{
												return(
													<div style={{display:'flex',border:'1px solid #ddd',padding:10,borderRadius:4,background:'white',marginBottom:5}}>
														<div><p>Ordine: {ordine._id}</p>
                              <p style={{fontWeight:300,marginTop:0,marginBottom:30}}>{ordine.items.length} articoli per {TotalP(ordine,item)}€ . {stampto(ordine.order_made_time)}</p>

                            <p className='tagS'>{this.orderType(ordine.type)}</p>
														<p>{ordine.indirizzoCompleto}</p></div>
                            <div style={{marginLeft:'auto'}}>
                              {ordine.StatusOrdine != 'CHIUD' ? (
                                <div onClick={()=>this.getStatusSync(ordine)} style={{maxWidth:100,marginRight:'auto',textAlign: 'center',fontSize:16}} className='buttonAction'>Stato ordine</div>

                              ) : null}
                            </div>
													</div>
												)
											})}
										</div>
                    ) : (
                      <div style={{marginTop:20}}>
                    {storicoPreno.map((ordine,i)=>{
												return(
													<div style={{display:'flex',border:'1px solid #ddd',padding:10,borderRadius:4,background:'white',marginBottom:5}}>
														<div><p>Ordine Numero: {ordine.nPrenotazione}</p>
                              <p style={{fontWeight:300,marginTop:0,marginBottom:30}}>{stampto(ordine.giorno,true)}</p>

                          <p className='tagS'>{ordine.fasciaOraria}</p>
                           <p></p></div>
                            <div style={{marginLeft:'auto'}}>
                              {ordine.status != 'CHIUDI' ? (
                                <div onClick={()=>this.getStatusSync(ordine,true)} style={{maxWidth:100,marginRight:'auto',textAlign: 'center',fontSize:16,marginLeft:0}} className='buttonAction'>Stato</div>

                              ) : null}
                            </div>
													</div>
												)
											})}
                    </div>
                    )}
                    
										

									</div>


									</div>



									<div style={{marginTop:100}}>
										<p onClick={()=>this.logOut()} style={{fontWeight:400,color:'red',cursor:'pointer'}}>Log Out</p>
										<p style={{}}>disconnetiti dal sito</p>
									</div>
							</div>

						</div>
					</div>
				</div>



				<div className='footr'>
          <Footer />
				</div>
			</div>



    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
	withAuthorization(condition),
)(HomePage);
